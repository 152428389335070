@import "~@angular/material/theming";

$mat-brand: (
  50: #e8f2fd,
  100: #c5def9,
  200: #9fc9f6,
  300: #79b3f2,
  400: #5ca2ef,
  500: #3f92ec,
  600: #398aea,
  700: #317fe7,
  800: #2975e4,
  900: #1b63df,
  A100: #ffffff,
  A200: #e0eaff,
  A400: #adc8ff,
  A700: #93b7ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue.
$app-primary: mat-palette($mat-brand);
// rgb(63, 146, 236)//#3F92EC
$app-accent: mat-palette($mat-pink, A200, A100, A400);
$mat-white: #fff;

// The warn palette is optional (defaults to red).
$app-warn: mat-palette($mat-red);
// Create the theme object (a Sass map containing all of the palettes).
$app-theme: mat-light-theme($app-primary, $app-accent, $app-warn);

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($app-theme);
